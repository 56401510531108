<template>
    <div>
        <highcharts v-if="loaded" :options="chartOptions" ref="chart"/>
        <div v-else class="d-flex justify-content-center">
            <b-spinner :style="{'color':constants.spinnerColor}"></b-spinner>
        </div>
    </div>
</template>

<script>
    import {Chart} from 'highcharts-vue'
    import Highcharts from 'highcharts'
    import exportingInit from "highcharts/modules/exporting"
    import dashboardChartMixin from '@/mixins/dashboardChartMixin'

    exportingInit(Highcharts)

    export default {
        name: 'StartupsByDateAndTrackerChart',
        mixins: [dashboardChartMixin],
        components: {
            highcharts: Chart
        },
        data() {
            return {
                loaded: false,
                chartName: "StartupsByDatesAndTrackers",
                chartOptions: {
                    chart: {
                        type: 'column',
                    },
                    credits: {
                        enabled: false
                    },
                    title: {
                        text: 'Startups Added By Date And Tracker',
                        align: 'left'
                    },
                    plotOptions: {
                        column: {
                            stacking: "normal",
                        },
                        series: {
                            borderWidth: 0,
                            dataLabels: {
                                enabled: true,
                                style: {
                                    textShadow: false,
                                    fontSize: "10px"
                                }
                            },
                        }
                    },
                    xAxis: {
                        categories: []
                    },
                    yAxis: {
                        type: "logarithmic",
                        title: {
                            text: ''
                        },
                        stackLabels: {
                            enabled: true,
                            style: {
                                fontWeight: 'bold',
                                color: ( // theme
                                    Highcharts.defaultOptions.title.style &&
                                    Highcharts.defaultOptions.title.style.color
                                ) || 'gray',
                                textOutline: 'none'
                            }
                        }
                    },
                    legend: {
                        align: 'left',
                        verticalAlign: 'top',
                        floating: false,
                        backgroundColor:
                            Highcharts.defaultOptions.legend.backgroundColor || 'white',
                        alignColumns: false,
                        maxHeight: 100,
                        itemDistance: 8,
                        itemMarginTop: 1,
                        itemMarginBottom: 3,
                        labelFormatter: function () {
                            return this.name
                        },

                    },
                    tooltip: {
                        headerFormat: '<b>{point.x}</b><br/>',
                        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
                    },
                    series: [],
                    colors: ['#043648', '#00afef', '#a50000', '#ffbf1c', '#0083b2', '#44bf2e', '#a11cff', '#ff5e1c']
                },
            }
        },
        methods: {
            fetchChartData(initialCall = false) {
                this.getChartData('StartupsByDatesAndTrackers').then(response => {
                    let data = response.data

                    this.chartOptions.series = data.trackers.map(item => ({
                        name: item.name,
                        data: item.data,
                        events: {
                            click: (e) => {
                                this.openStartupsModal(e.point.category, item.name)
                            }
                        }
                    }))

                    this.chartOptions.xAxis.categories = data.dates

                    if (!initialCall) {
                        this.$refs.chart.chart.hideLoading()
                    } else {
                        this.loaded = true
                    }
                })
            },

            openStartupsModal(date, tracker) {
                this.$emit('showStartups', [this.chartName, `Startups added to tracker "${tracker}" in "${date}"`, JSON.stringify([date, tracker])])
            }
        }
    }
</script>
